import React from 'react';

const Container = ({ children, className }) => {

    return (
        <div className={`px-4 md:px-24 lg:px-40 py-8 lg:py-20 mx-auto sm:max-w-xl md:max-w-full${` `}${className}`}>{children}</div>
    )
}

export default Container
